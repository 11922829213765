.carousel
    background-color: rgb(25, 25, 25)
    height: 60vh

    .carousel-inner
        height: 100%

        .carousel-item
            height: 100%

            img
                position: relative
                top: 50%
                left: 50%
                transform: translate(-50%, -50%)
                max-width: 100vw
                max-height: 60vh
