footer
    width: 100%
    background: black

    display: inline-flex
    flex-wrap: wrap
    justify-content: space-evenly
    grid-gap: 1rem

    *
        color: white !important

    section
        padding: 0

        > div
            display: grid
            grid-template-columns: 1fr
            grid-gap: .5rem
            justify-items: center

        h2
            text-align: center
            margin-bottom: .5rem
            border-bottom: 1px solid white

        menu
            display: grid
            grid-template-columns: 1fr
            grid-gap: .5rem
            padding: 0

            a
                text-align: center
                border: none
                padding: 0

        dl
            dt
                font-size: .75rem

            dd
                padding-bottom: .5rem
