.file__list
    display: inline-flex
    flex-wrap: wrap
    grid-gap: 1rem
    padding: .5rem 0

    a
        padding: 1rem 1rem 1rem 3rem
        background-size: 2rem
        background-position: .5rem center
        background-repeat: no-repeat
        background-image: url(./images/file.png)

        transition: border-bottom-color .25s

        &:hover
            background-color: var(--clr-almostWhite)

        &.file__icon__image
            background-image: url(./images/image.png)
