#sponsors
    display: inline-flex
    flex-wrap: wrap
    grid-gap: 1rem
    justify-content: space-evenly
    background: black

    width: 100%
    padding: .5rem 0

    a
        display: grid
        align-content: center
        justify-content: center

        img
            max-width: 8rem
            max-height: 4rem
