\:root
    --clr-black: black
    --clr-almostBlack: rgb(25, 25, 25)
    --clr-gray: rgb(150, 150, 150)
    --clr-white: white
    --clr-action: #833eaf
    --clr-hoverWhite: rgb(245, 245, 245)
    --clr-almostWhite: rgb(235, 235, 235)
    --clr-red: red
    --clr-almostRed: rgba(228, 5, 33, .2)
    --clr-green: rgb(34, 139, 34)
    --clr-almostGreen: #9ec99e
    --clr-blue: rgba(45, 46, 135, .2)
    --clr-almostBlue: rgba(45, 46, 135, .4)
    --clr-lightBlue: rgba(214, 228, 255, 0.53)
    --clr-yellow: #bdb150

@media (prefers-color-scheme: dark)
    \:root
        --clr-black: white
        --clr-almostBlack: rgb(245, 245, 245)
        --clr-gray: rgb(150, 150, 150)
        --clr-white: black
        --clr-action: #8e65ac
        --clr-hoverWhite: rgb(25, 25, 25)
        --clr-almostWhite: rgb(50, 50, 50)
        --clr-red: red
        --clr-almostRed: rgba(208, 55, 75, 0.2)
        --clr-green: green
        --clr-almostGreen: #2f412f
        --clr-blue: rgba(100, 102, 203, 0.4)
        --clr-almostBlue: rgba(116, 117, 188, 0.2)
        --clr-lightBlue: rgba(97, 104, 124, 0.53)
        --clr-yellow: #fff68e

        body:has(#administration)
            --clr-black: black
            --clr-almostBlack: rgb(25, 25, 25)
            --clr-gray: rgb(150, 150, 150)
            --clr-white: white
            --clr-action: #833eaf
            --clr-hoverWhite: rgb(245, 245, 245)
            --clr-almostWhite: rgb(235, 235, 235)
            --clr-red: red
            --clr-almostRed: rgba(228, 5, 33, .2)
            --clr-green: rgb(34, 139, 34)
            --clr-almostGreen: #9ec99e
            --clr-blue: rgba(45, 46, 135, .2)
            --clr-almostBlue: rgba(45, 46, 135, .4)
            --clr-lightBlue: rgba(214, 228, 255, 0.53)
            --clr-yellow: #bdb150
