#login
    min-height: 20rem

    display: grid
    grid-template-columns: 1fr
    grid-gap: 1rem
    justify-items: center
    align-items: center
    align-content: center

    span
        color: var(--clr-red)
        opacity: 0
        animation: show .5s forwards

    input
        font-size: 1.25rem
        padding: 0 .5rem
        border: none
        border-bottom: 1px solid var(--clr-black)
        border-radius: .25rem
        background: var(--clr-almostWhite)

    button
        background: transparent
        font-size: 1.2rem

        &:hover
            opacity: .7
            cursor: pointer

@keyframes show
    from
        opacity: 0
    to
        opacity: 1
