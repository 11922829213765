menu
	width: 100%
	min-height: 20vh
	padding: 0 5vw

	display: inline-flex
	align-content: center
	justify-content: center
	flex-wrap: wrap
	grid-gap: 0 3rem

	a
		color: var(--clr-black)
		padding: 1rem 0 0 0
		border-bottom: 1px solid rgba(0, 0, 0, .3)
		transition: border-bottom-color .25s

		&.active, &:hover
			border-bottom-color: var(--clr-black)

		&.classic
			border: none

		img
			height: 1.5rem

#banner__title
	display: grid
	grid-gap: 1rem 3rem
	align-content: flex-end
	align-items: center
	justify-content: center
	justify-items: center

	grid-template-columns: auto auto

	min-height: 20vh

	h1
		font-size: 3rem
		text-align: center
		text-transform: uppercase

	img
		padding-top: 1rem
		height: 10rem
		justify-self: center

@media (max-width: 768px)
	#banner__title
		grid-template-columns: auto
