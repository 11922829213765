.event
    display: grid
    grid-template-columns: auto 1fr
    grid-gap: 3rem

    h2
        grid-column: 1 / span 2

@media (max-width: 768px)
    .event
        grid-template-columns: 1fr

        h2
            grid-column: 1
