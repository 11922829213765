.calendar
    display: grid
    grid-template-columns: 1fr
    align-content: baseline

    .calendar__menu
        display: grid
        grid-template-columns: 3rem 1fr 3rem
        grid-auto-rows: 3rem

        > *
            display: flex
            align-items: center
            justify-content: center

        button
            transition: opacity .25s

            &:hover
                opacity: .5
                cursor: pointer

    .calendar__header, .calendar__body
        display: grid
        grid-template-columns: repeat(7, 3.5rem)

        > div
            display: flex
            align-items: center
            justify-content: center

    .calendar__header
        grid-auto-rows: 2rem

        border-bottom: 1px solid var(--clr-black)

        > div
            color: var(--clr-gray)
            font-size: .8rem

    .calendar__body
        grid-auto-rows: 3rem

        > div
            &:hover
                background: var(--clr-hoverWhite)

            &.calendar__outMonth
                color: var(--clr-gray)

            &.calendar__today
                background-color: var(--clr-blue)

                &:hover
                    background-color: var(--clr-almostBlue)

            &.calendar__hasEvent
                background-color: var(--clr-almostRed)

                &:hover
                    cursor: pointer

            &.calendar__selected
                text-decoration: underline

@media (max-width: 768px)
    .calendar
        .calendar__header, .calendar__body
            grid-template-columns: repeat(7, 1fr)
