#loading
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: var(--clr-white)

    display: grid
    justify-content: center
    align-content: center

    h1
        text-align: center
