@import url('./font.css')

\:root
    --padding-horizontal: 10vw

*
    padding: 0
    margin: 0
    box-sizing: border-box
    font-family: 'Poppins', sans-serif
    color: var(--clr-black)

body
    display: grid
    grid-template-columns: 1fr
    background: var(--clr-white)

button
    border: none
    background-color: transparent

a
    text-decoration: none

    &:hover
        opacity: .7

section, footer
    padding: 2rem var(--padding-horizontal)

section
    > h2
        padding: .5rem 0

@media (max-width: 768px)
    \:root
        --padding-horizontal: 2rem
