.list
    display: grid
    grid-template-columns: 1fr
    grid-gap: 1rem
    align-content: baseline

    .list__empty
        align-self: center
        justify-self: center

    > dl
        border-left: 1px solid var(--clr-black)
        padding-left: 1rem

        .list__date
            font-size: .75rem

        dd
            padding: .25rem 0
